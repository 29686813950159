import comingMessage from './coming.js';
import messages from './messages.js';
import wrong from './wrong.js';
import wsBackend from './wsBackend.js';

const tests = {
  ws: {
    update: {
      startTime: 3500,
      timeInterval: 1000,
      data: [comingMessage],
    },
    newMessage: {
      startTime: 1000,
      timeInterval: 100,
      data: messages
    },
    wrong: {
      startTime: 2000,
      timeInterval: 1000,
      data: [wrong]
    }
  },
  http: {}
};
const backend = {
  ws: wsBackend,
  http: null
}
const test = {};

test.regist = newHandlers => {  // {ws, http}
  console.log('Starting tests!!!!');
  for (let connection in newHandlers) {
    if (newHandlers.hasOwnProperty(connection)) {
      console.log('Registing ' + connection + ' connection...');
      backend[connection].response = newHandlers[connection];
      test[connection] = { send: backend[connection].send };
      console.log('For ' + connection + ' connection...');
      let handler = newHandlers[connection];
      for (let type in tests[connection]) {
        if (tests[connection].hasOwnProperty(type)) {
          console.log('Creating ' + type + ' type event...');
          let { data, startTime, timeInterval } = tests[connection][type];
          data.forEach((mes, i) => {
            let eventData = {};
            eventData[type] = mes;
            setTimeout(handler, startTime + (timeInterval * i), { data: JSON.stringify(eventData) });
            setTimeout(console.log, startTime + (timeInterval * i) - 50,
              'Sending ' + type + ' event from ' + connection + ' connection...');
            // setTimeout(console.log, startTime + (timeInterval * i) - 40, {mes});
          });
        }
      }
      setTimeout( () => {
        if (test[connection].hasOwnProperty('onopen'))
          test[connection].onopen();
      }, 0);
    }
  }
  console.log('Events setting completed!!!!');
};

export default test;
