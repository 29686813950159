import React, { useState, useEffect } from 'react';
import MessageArea from './MessageArea.jsx';
import InputArea from './InputArea.jsx';
import ChatRoomHeader from './ChatRoomHeader.jsx';

const room = {
  addr: '',
  id: null,
  name: 'Untitle~',
  attenders: [],
  messages: [],
  sort: () => {
    room.messages.sort( (a, b) => a.id - b.id );
    for (let ii = room.messages.length, i = ii - tempMap.unconfirmedCount; i < ii; i++) {
      tempMap[room.messages[i].id % tempMap.range] = i;
    }
  },
  markSource: (msg, userId) => {
    msg.sender.id === userId ? msg.source = 'self' : msg.source = 'others';
    if(msg.type === 'info') msg.source = 'info';
  },
  markSources: (msgs, userId) => msgs.forEach( msg => room.markSource(msg, userId) )
};

const tempMap = [];
tempMap.range = 10000;
tempMap.shift = 999;
tempMap.unconfirmedCount = 0;

function ChatRoom(props) {
  const {user, test, roomID, roomAddr, ...rest} = props;
  const [text, setText] = useState('');
  const [res, setRes] = useState();
  const [pause, setPause] = useState(false);
  // handel input staff
  const handleInputChange = e => {
    setText(e.target.value);
  };
  const handleInputEnter = e => {
    if (e.key === 'Enter') {
      setText('');
      if (text.replace(/　/g, '').replace(/ /g, '').length === 0) {
        setText('');
        return;
      }
      const last = room.messages.length;
      const lastId = room.messages[last - 1].id;
      const newMessage = {
        tempKey: tempMap.shift + lastId,
        type: 'text',
        content: text
      };
      room.ws.send(JSON.stringify({ newMessage }));
      tempMap[newMessage.tempKey % tempMap.range] = last;
      tempMap.unconfirmedCount++;
      room.messages.push({
        id: newMessage.tempKey,
        type: newMessage.type,
        content: newMessage.content,
        time: new Date(),
        sender: user,
        source: 'self',
        unconfirmed: true
      });
      if(text === '##') console.log(room.messages);           //////// debug line
      if(text[0] === '@') console.log(eval(text.slice(1)));   // DEBUG:
    }
  };
  const handleMessageScroll = e => {
    const databaseStartIndex = 1;
    const requestLength = 10;
    let position = e.target.scrollTop;
    if(position < 5) {
      const lastIndex = room.messages[0].id;
      if(lastIndex > databaseStartIndex) {
        const startIndex = lastIndex > requestLength ? lastIndex - requestLength : databaseStartIndex;
        room.ws.send(JSON.stringify({ historyMessage: { startIndex, lastIndex } }));
      }
    }
  };
  const wsDataHandlers = { // categorize handlers by data type
    historyMessage: data => {
      if(data.length === 0)
        return;
      const last = data[data.length-1].id;
      const start = room.messages[0].id;
      if(last >= start)
        return;
      room.markSources(data, user.id);
      room.messages = data.concat(room.messages);
      if(last >= start)
        room.sort();
      setPause(true);
      setRes(last);
    },
    update: data => {
      if (data.hasOwnProperty('coming')) { // new user coming
        const msg = data.comingMessage;
        msg.source = 'info';
        room.messages.push(msg);
        setRes(msg);
      } else { // update the information of the room
        room.markSources(data.messages, user.id);
        for (let info in data) {
          if (data.hasOwnProperty(info)) {
            room[info] = data[info];
          }
        }
        setRes(data.name);
      }
    },
    newMessage: data => {
      if (data.hasOwnProperty('tempKey')) { // new message from self
        const message = data.message;
        message.source = 'self';
        room.messages[tempMap[data.tempKey % tempMap.range]] = message;
        delete tempMap[data.tempKey % tempMap.range];
        tempMap.unconfirmedCount--;
        room.sort();
        setRes(message);
      } else { // new message from others
        room.markSource(data, user.id);
        room.messages.push(data);
        setRes(data);
      }
    }
  }
  const wsHandler = e => {
    const data = JSON.parse(e.data);
    // console.log(data);
    for (let type in data) {
      if (data.hasOwnProperty(type)) {
        wsDataHandlers[type] ?
          wsDataHandlers[type](data[type]) :
          console.log('%cerror: wrong type from ws, ' + type, 'color: red;font-size: 20px;');
      }
    }
  };

  useEffect(() => {
    room.id = roomID;
    room.addr = roomAddr;
    // create websocket connection
    room.ws = new WebSocket('wss://' + room.addr + '/ws/chat/' + room.id + '/');
    room.ws.onmessage = wsHandler;
    room.ws.onclose = e => {
    	console.error('Chat socket closed unexpectedly');
    };

    // test wsBackend
    // test.regist({ ws: wsHandler }); // simulate websocket
    // room.ws = test.ws;

    // update room info
    room.ws.onopen = e => {
      console.log('ws is open');
      room.ws.send(JSON.stringify({ update: {} }));
    };
    return () => {
      // close websocket connection
      room.ws.close();
    }
  }, []);
  useEffect( () => console.log('got it!'), [res]);

  return (
    <div {...rest} >
      <ChatRoomHeader>
        {room.name}
      </ChatRoomHeader>
      <MessageArea handleScroll={handleMessageScroll} pause={pause} setpause={setPause} >
        {room.messages}
      </MessageArea>
      <InputArea
        handleChange={handleInputChange}
        text={text}
        handleEnter={handleInputEnter} />
    </div>
  );
};

export default ChatRoom;
