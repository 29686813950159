import React from 'react';
import RoomListHeader from './RoomListHeader.jsx';

const RoomList = props => {
  const {handleEnter, handelRefrech, roomList, setRoomID, ...rest} = props;
  const options = {  year: 'numeric', month: 'long', day: 'numeric' };
  const handelClick = e => {
    const id = e.target.parentElement.id;    // string!!!!!
    // console.log(id.slice(4));
    setRoomID(Number(id.slice(4)));
    // if(id === 'room0')
      handleEnter(e);
  };
  const rows = roomList.map( room => (
    <tr key={room.id.toString()} id={'room' + room.id.toString()} onClick={handelClick} >
      <td>{room.id}</td>
      <td>{room.name}</td>
      <td>{room.creator.name}</td>
      <td>{'N/A'}</td>
      <td>{room.updateTime.toLocaleDateString(undefined, options)}</td>
      <td>{room.startTime.toLocaleDateString(undefined, options)}</td>
    </tr>
  ) );
  return (
    <div {...rest} >
      <RoomListHeader>
        Room List (welcome onboard)
      </RoomListHeader>
      <table>
        <thead>
          <tr>
            <th>聊天室代號</th>
            <th>聊天室名稱</th>
            <th>開房間的人</th>
            <th>房間成員</th>
            <th>更新時間</th>
            <th>建立時間</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
      <div className={'flexbox button-bar'} >
        <button type="button" onClick={handleEnter} >Enter</button>
        <button type="button" onClick={handelRefrech} >Refresh</button>
      </div>
    </div>
  );
};

export default RoomList;
