const oldMessage = {
  initial: {
    id: 0, type: 'info', content: 'Chat room 木星與他的快樂夥伴們～ has been created.',
    sender: {id: 999, name: 'System'},
    time: new Date('2018-08-14 10:04:12.492653+08:00')
  },
  normal: {
    id: 7, type: 'text', content: 'old message',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2019-08-14 10:04:12.492653+08:00')
  }
};

export default oldMessage;
