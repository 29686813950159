const messages = [
  {
    id: 505, type: 'text', content: 'Hello world',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 04:34:59.238563+08:00')
  },
  {
    id: 506, type: 'text', content: '有人在嗎？',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 05:44:39.946284+08:00')
  },
  {
    id: 507, type: 'text', content: '起床啦！！',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-14 10:04:12.492653+08:00')
  },
  {
    id: 508, type: 'text', content: '老鍋會暴斃 lol',
    sender: {id: 0, name: 'SN'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 509, type: 'text', content: '我剛也睡著了',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 510, type: 'info', content: 'SN leaved.',
    sender: {id: 999, name: 'System'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 511, type: 'text', content: '吃啥',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 512, type: 'text', content: '所以？',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 513, type: 'text', content: '在想',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 514, type: 'text', content: '好喔',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 515, type: 'text', content: '我開始弄聊天室前端了',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 516, type: 'text', content: 'Subway?',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 517, type: 'text', content: '也可',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 518, type: 'text', content: '不過我要先尬賽',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 519, type: 'text', content: '好了叫我',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 520, type: 'text', content: '好了',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 521, type: 'text', content: '去騎車',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 522, type: 'text', content: '到了',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 523, type: 'text', content: '剛弄好測試訊息XD',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 524, type: 'text', content: '我下去',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 525, type: 'text', content: 'https://postwoman.io/realtime',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 526, type: 'text', content: '2020-08-14 04:34:59.238563+08:00',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 527, type: 'text', content: '新訊息的暫時鍵，訊息排序(?)，載入舊訊息，顯示使用者名稱，標題列更多資訊',
    sender: {id: 1, name: 'Wenfey'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 528, type: 'info', content: 'To-do list has been created.',
    sender: {id: 999, name: 'System'},
    time: new Date('2020-08-25 18:49:54.472053+08:00')
  },
  {
    id: 529, type: 'text', content: '你真的不睡嗎？？？',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-26 06:13:57.957433+08:00')
  }
];

export default messages;
