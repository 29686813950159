import React from 'react'

const InputArea = (props) => {
  const {text, handleChange, handleEnter, ...rest} = props;

  return (
    <div className='input-area flexbox' {...rest} >
      <input type='text'
             placeholder='typing here...'
             value={text}
             onKeyPress={handleEnter}
             onChange={handleChange} />
    </div>
  )
}

export default InputArea;
