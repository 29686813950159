const comingMessage = {
  coming: {id: 0, name: 'SN'},
  comingMessage: {
    id: 530, type: 'info', content: 'SN joined.',
    sender: {id: 999, name: 'System'},
    time: new Date('2020-08-26 06:16:57.957433+08:00')
  },
  attenders: [
    {id: 1, name: 'Wenfey'},
    {id: 2, name: 'Frank'},
    {id: 0, name: 'SN'}
  ]
};

export default comingMessage;
