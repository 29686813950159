import roomLastInfo from './roomLastInfo.js';
import oldMessage from './oldMessage.js';
import cycleMessage from './cycleMessage.js';

const wsBackend = {};

let count = 531;

wsBackend.send = e => {
  const data = JSON.parse(e);
  for (let type in data) {
    if (data.hasOwnProperty(type)) {
      handlers[type] ?
        handlers[type](data[type]) :
        console.log('error: wrong type from ws (backend), ' + type);
    }
  }
};

const handlers = {
  update: req => {
    wsBackend.response({ data: JSON.stringify({ update: roomLastInfo }) });
  },
  newMessage: m => {
    const res = { data: JSON.stringify({
      newMessage: {
        tempKey: m.tempKey,
        statusCode: 200,
        message: {
          id: count++, type: m.type, content: m.content,
          sender: {id: 1, name: 'Wenfey'},
          time: new Date()
        }
      }
    }) };
    setTimeout(wsBackend.response, 200, res);
    if(count % 4 === 0) {
      const message = cycleMessage[count / 4 % 3];
      message.id = count++;
      message.time = new Date();
      setTimeout(wsBackend.response, 400, { data: JSON.stringify({ newMessage: message }) });
    }
  },
  historyMessage: req => {
    const historyMessage = [];
    if(req.startIndex === 0) {
      historyMessage.push(oldMessage.initial);
      req.startIndex++;
    }
    for(let i = req.startIndex; i < req.lastIndex; i++) {
      let message = {};
      for (var info in oldMessage.normal) {
        if (oldMessage.normal.hasOwnProperty(info)) {
          message[info] = oldMessage.normal[info];
        }
      }
      message.id = i;
      message.content += i;
      historyMessage.push(message);
    }
    wsBackend.response({ data: JSON.stringify({ historyMessage }) });
  }
}

export default wsBackend;
