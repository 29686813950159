const roomLastInfo = {
  id: 5682,
  name: '木星與他的快樂夥伴們～',
  attenders: [
    {id: 1, name: 'Wenfey'},
    {id: 2, name: 'Frank'},
    {id: 0, name: 'SN'}
  ],
  messages: [
    {
      id: 0, type: 'text', content: '現在甚麼情形？',
      sender: {id: 1, name: 'Wenfey'},
      time: new Date('2020-08-14 04:34:59.238563+08:00')
    },
    {
      id: 1, type: 'text', content: '聊天室要載入了嗎？',
      sender: {id: 1, name: 'Wenfey'},
      time: new Date('2020-08-14 05:44:39.946284+08:00')
    },
    {
      id: 2, type: 'text', content: '睡',
      sender: {id: 2, name: 'Frank'},
      time: new Date('2020-08-14 23:16:14.492653+08:00')
    },
    {
      id: 3, type: 'text', content: `招喚強化學習
醫療圖像的 最終里程碑 應當是強化學習
只要每位醫生 當作關主
也就是對機器來說 的遊戲環境
遊戲環境 會告訴機器它是否答對
一旦遊戲環境中 有9成的關主都認為 正確答案是A,那遊戲環境的真值 就是A
然後 這時候機器對一直尋找圖片的意義 直到它找到A
對*會`,
      sender: {id: 0, name: 'SN'},
      time: new Date('2020-08-15 10:56:32.957433+08:00')
    },
    {
      id: 4, type: 'text', content: '我都到台北了',
      sender: {id: 2, name: 'Wenfey'},
      time: new Date('2020-08-15 10:37:54.957433+08:00')
    }
  ]
};

export default roomLastInfo;
