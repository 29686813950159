const cycleMessage = [
  {
    id: 7, type: 'text', content: '起床啦！！',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-14 10:04:12.492653+08:00')
  },
  {
    id: 8, type: 'text', content: '老鍋會暴斃 lol',
    sender: {id: 0, name: 'SN'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  },
  {
    id: 9, type: 'text', content: '我剛也睡著了',
    sender: {id: 2, name: 'Frank'},
    time: new Date('2020-08-14 14:31:54.957433+08:00')
  }
];

export default cycleMessage;
