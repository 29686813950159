import React from 'react';

const MessageText = (props) => {
  const { children, source, unconfirmed, ...rest } = props;
  let unconfirmedClass = '';
  let name;
  unconfirmed ? unconfirmedClass = ' unconfirmed' : unconfirmedClass = ' ';
  if(source === 'others') {
    name = (
      <div className={'sender-name'} >
        {children.sender.name}
      </div>
    );
  }
  return (
    <div className={'message-container ' + source} {...rest} >
      {name}
      <div className={'message-text ' + source + unconfirmedClass} >
        {children.content}
      </div>
    </div>
  );
};

export default MessageText;
