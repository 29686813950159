import React, { useRef, useEffect } from 'react';
import MessageText from './MessageText.jsx'

const MessageArea = (props) => {
  const {children, handleScroll, pause, setpause, ...rest} = props;
  const ref = useRef(null);
  const lastMsgConfirmed = children[children.length - 1] && children[children.length - 1].unconfirmed;
  const messages = children.map( message => <MessageText
    key={message.id.toString()}
    source={message.source}
    unconfirmed={message.unconfirmed} >
      {message}
    </MessageText> );

  useEffect( () => {
    if(pause === false) {
      const area = ref.current;
      area.scrollTop = area.scrollHeight;
    }
    setpause(false);
  }, [ children.length, lastMsgConfirmed ]);  // 長度變化或最後一個訊息得到確認時，將小視窗拉到最下面

  return (
    <div ref={ref} onScroll={handleScroll} className='message-area flexbox' {...rest}>
      {messages}
    </div>
  );
};

export default MessageArea;
