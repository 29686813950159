import React from 'react';

const RoomListHeader = (props) => {
  const {children, ...rest} = props;
  return (
    <div className='room-list-header' {...rest} >{children}</div>
  );
};

export default RoomListHeader;
