import React from 'react';

const ChatRoomHeader = (props) => {
  const {children, ...rest} = props;
  return (
    <div className='chat-room-header' {...rest} >{children}</div>
  );
};

export default ChatRoomHeader;
