import React, { useState } from 'react';
import ChatRoom from './component/ChatRoom.jsx';
import RoomList from './component/RoomList.jsx';
import test from './testing/test.js';

const addr = 'frank.wenfey.vettesla.com';
const user = { id: 1, name: 'Wenfey' };
const testRooms = [
  {
    id: 0, name: '木星與他的快樂夥伴們～', creator: { id: 1, name: 'Wenfey' },
    startTime: new Date('2018-08-14 10:04:12.492653+08:00'),
    updateTime: new Date('2020-08-26 06:16:57.957433+08:00')
  },
  {
    id: 1, name: '鐵三角身心部與閒聊', creator: { id: 1, name: 'Wenfey' },
    startTime: new Date('2018-08-14 10:04:12.492653+08:00'),
    updateTime: new Date('2020-08-26 06:16:57.957433+08:00')
  },
  {
    id: 2, name: '獸醫藥醫鐵三角', creator: { id: 1, name: 'Wenfey' },
    startTime: new Date('2018-08-14 10:04:12.492653+08:00'),
    updateTime: new Date('2020-08-26 06:16:57.957433+08:00')
  }
];

function App(props) {
  const [ viewKey, setView ] = useState('list');
  const [ roomID, setRoomID ] = useState(null);
  const handleEnter = e => {
    // user.id = 2;
    setView('room');
  };
  const handelRefrech = e => {
    console.log('Refresh');
  };

  const view = {
    list: <RoomList roomList={testRooms} handelRefrech={handelRefrech} handleEnter={handleEnter} setRoomID={setRoomID} />,
    room: <ChatRoom user={user} test={test} roomID={roomID} roomAddr={addr} />
  };

  return (
    <div className="App" >
      {view[viewKey]}
    </div>
  );
}

export default App;
